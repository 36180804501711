import { navigate } from 'gatsby';
import { heading, description } from './utils';
import { Typography } from '@/components/Typography';
import './Hero.scss';
import { RightIcon } from '@/static/assets/icons/Navigation';

const Hero = () => {
  return (
    <div className="not-found-container">
      <div className="not-found__heading">
        <Typography component="h1" typographyKind="header-primary-large">
          {heading}
        </Typography>
      </div>
      <div className="not-found__description flex-row align-center">
        <Typography component="h2" typographyKind="header-secondary-medium">
          {description}
        </Typography>
        <div
          className="not-found__description--icon"
          onClick={() => navigate('/')}
        >
          <RightIcon />
        </div>
      </div>
    </div>
  );
};

export default Hero;
