import Layout from '@/components/Layout';
import Hero from '@/sections/NotFound/Hero';

const NotFoundPage = () => {
  return (
    <Layout>
      <Hero />
    </Layout>
  );
};

export default NotFoundPage;
